import React from 'react'
import { Link } from 'gatsby';

const Index = () =>
    <div className="MentionsLegales">
        <div className="MentionsLegales-Layout-container">
            <div className="MentionsLegales-Layout-cell">
                <div className="MentionsLegales-Layout-contentWrapper">
                    <Link to="/" className="MentionsLegales-Gohome">page d'accueil</Link>
                    <div className="home_logos">
                        <div className="home__logo-wrapper home__logo-wrapper--sephora">
                            <a data-trackable="1" title="sephora" target="_blank" rel="noopener" href="https://www.sephora.fr/" className="home__logo-sephora"></a></div><div className="home__logo-amp">&amp;</div><div className="home__logo-wrapper home__logo-wrapper--laneige"><a data-trackable="1" title="laneige" target="_blank" rel="noopener" href="https://www.laneige.com/int/en/" className="home__logo-laneige"></a>
                        </div>
                    </div>
                    <hr/>
                    <h2> Mentions légales<br/>SMILE ALL DAY/NIGHT</h2>
                    <p>
                        Le site smilealldayandnight.fr est édité par la société G&amp;R, 
                        société par actions simplifiée, immatriculée au Registre du Commerce et des Sociétés 
                        de Paris sous le numéro 788 810 992, dont le siège social est sis 7, boulevard Flandrin, 
                        75116 Paris.<br/>
                        N°SIREN : 788 810 992<br/>
                        N° TVA : FR36788810992<br/>
                        Email : contact@gustave-et-rosalie.com<br/>
                        Pays : France 
                    </p>
                    <p>
                        <a style={{textDecoration:'underline'}} rel="noopener" target="_blank" href="https://www.gustave-et-rosalie.com/mentions-legales-smilealldayandnight-fr">Le présent document</a> a 
                        pour but de fixer les conditions générales d’utilisation des services de smilealldayandnight.fr. 
                        En fonction de l'évolution du site, nous nous réservons 
                        la possibilité de modifier celles-ci et vous demandons par conséquent de vous y reporter régulièrement.
                    </p>
                    <p>
                        <strong>Directeur de la publication</strong><br/>
                        Antoine Constantin
                    </p>
                    <p>
                        <strong>Hébergement</strong><br/>
                        OVH 2 rue Kellermann, 59100 Roubaix<br/>
                        Site Web : http://www.ovh.com/<br/>
                        Téléphone : 09 72 10 10 07
                    </p>
                    <hr/>
                    <h2>Crédits</h2>
                    <p>
                        Une idée de<br/>Paris Tonic
                    </p>
     
                </div>
            </div>
        </div>
    </div>

export default Index;

